<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 공사현장 -->
          <c-plant type="edit" label="복사대상 공사현장" name="plantCd" v-model="searchParam.plantCd" :reject="popupParam.plantCd" :isNotAuth="true" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="processTable"
      title="타 공사현장 공종 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      selection="multiple"
      rowKey="processCd"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="공사현장에 추가" icon="add" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'project-company-process-pop',
  props: {
    popupParam: {
      type: Object,
      default: function () {
        return {
          plantCd: null,
        };
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function () {
        return 'auto';
      },
    },
  },
  data() {
    return {
      upProcessItems: [],
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '복사대상 공사현장',
            align: 'center',
            style: 'width: 350px',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '100%',
      },
      searchParam: {
        useFlag: 'Y',
        processLevelCd: '20',
        plantCd: null,
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  watch: {
    contentHeight() {
      this.grid.height = '500px';
    },
  },
  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(selectConfig.pjm.process.list.url, this.searchParam.plantCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['processTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공종을 선택하세요.', // 공종을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          item.plantCd = this.popupParam.plantCd;
          item.regUserId = this.$store.getters.user.userId;
        });
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '기존 공종/작업/작업단계 데이터가 초기화 되며\n\r(기존공정데이터는 사라집니다.)\n\r선택한 공사현장의 공종/작업/작업단계가\n\r해당 공사현장 데이터로 복사됩니다\n\r그대로 진행하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.pjm.other.save.url;
            this.$http.type = 'POST';
            this.$http.param = selectData;
            this.$http.request(() => {
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '공사현장 공종데이터가 저장되었습니다.', /* 공사현장 공종데이터가 변경되었습니다.. */
                type: 'success', // success / info / warning / error
              });
              this.$emit('closePopup');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  },
};
</script>
